<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content> 
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0" v-if="userData">
          {{ userData.username }}
        </p>
      </div>
      <b-avatar
        v-if="userData"
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.username"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'apps-calendar' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CalendarIcon" class="mr-50" />
      <span>Book a Time</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-products' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Products</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'apps-view-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-license' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="FileIcon" class="mr-50" />
      <span>License</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";
import { signOut } from "@/auth/amplify/amplifyService";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      //userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  computed: {
    userData: function(){
       return this.$store.state['app-users'].userInfo
    }
  },
  methods: {
    async logout() {
      try {
        await signOut();
        // reset all state
        this.$store.commit("app-users/RESET_USER_MODULE")
        this.$store.commit("app-ecommerce/RESET_ECOMMERCE_MODULE")
        this.$store.commit("app-products/RESET_PRODUCTS_MODULE")
        this.$store.commit("app-customers/RESET_CUSTOMERS_MODULE")
        this.$store.commit("app-market/RESET_MARKET_MODULE")
        this.$store.commit("app-home/RESET_HOME_MODULE")

        // Remove userData from localStorage
        localStorage.removeItem("userData");

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      } catch (error) {
        console.log("[error]", error);
      }
    },
  },
};
</script>
